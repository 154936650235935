import request from '@/plugins/axios'

/** S 合作商列表 **/
// 合作商列表
export const apiPartnerLists = (params: any) => request.get('/partner.partner/lists', {params})

// 合作商单点登录
export const apiPartnerSso = (params: any) => request.post('/partner.partner/partnerSso', params)

// 添加合作商
export const apiPartnerAdd = (params: any) => request.post('/partner.partner/add', params)

// 合作商详情
export const apiPartnerDetail = (params: any) => request.get('/partner.partner/detail', {params})

// 编辑合作商
export const apiPartnerEdit = (params: any) => request.post('/partner.partner/edit', params)

// 冻结合作商
export const apiPartnerStatus = (params: { id: number , status: number }) => request.post('/partner.partner/status', params)

// 修改备注
export const apiChangeRemark = (params: { id: number , remark: string }) => request.post('/partner.partner/remark', params)

// 修改账号
export const apiPartnerChangeSuperAdmin = (params: any) => request.post('/partner.partner/account', params)

// 充值
export const apiPartnerCharge = (params: any) => request.post('/partner.recharge/recharge', params)

// 充值列表
export const apiPartnerChargeLists = (params: any) => request.get('/partner.recharge/lists', {params})

// 变动类型
export const apiPartnerChargeOtherLists = () => request.get('/partner.recharge/otherLists')

/** E 合作商列表 **/