
import { apiPartnerChargeLists, apiPartnerChargeOtherLists } from '@/api/partner'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
// import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
@Component({
    components: {
        lsPagination,
        // ExportData,
        LsDialog
    }
})
export default class PartnerList extends Vue {
    /** S Data **/

    // apiPartnerChargeLists = apiPartnerChargeLists;

    pager: RequestPaging = new RequestPaging();

    searchObj: any = {
        status: '',
        keyword: '',
        type: '',
        create_time: ''
    };

    changeList: any = []
    /** E Data **/

    /** S Methods **/

    // 获取合作商列表
    getPartnerLists(): void {
        this.pager.request({
            callback: apiPartnerChargeLists,
            params: {
                ...this.searchObj
            }
        })
    }

    getChangeTypeLists() {
        apiPartnerChargeOtherLists().then(res => {
            this.changeList = res;
        })
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, '')
        })
        this.getPartnerLists()
    }

    /** E Methods **/

    /** S ods **/
    created() {
        this.getPartnerLists()
        this.getChangeTypeLists()
    }
}
